@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Oswald:wght@400;600&family=Poppins:wght@400;600&family=Roboto+Condensed:wght@400;700&display=swap');

.App {
  text-align: center;
  max-width: 480px;
  margin: 0 auto !important;
  float: none !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  height: 50px;
  max-height: 50px;
}

.logo img {
  width: 33vw;
}

.header {
  height: calc(33vh + 145px);
}

.header svg {
  max-width: 480px;
  ;
}

.hero {
  background-size: cover;
  height: calc(33vh + 100px);
  max-height: calc(33vh + 100px);
  position: relative;
  z-index: -2;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 28px;
}

.heading {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  text-align: left;
  position: absolute;
  bottom: 0;
  min-height: 80px;
  width: 100%;
  box-sizing: border-box;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 33%, #c9082c);
  z-index: 1;
}

.heading::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 1));
  z-index: -1;
  border-radius: 28px;
}

.heading-icon {
  font-size: 2em;
  color: #c9082c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .5em;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 64px;
  height: 64px;
}

.steps {
  font-size: 1.8em;
  font-weight: 600;
  margin: .25em 0;
}

.estimated_time {
  font-weight: 400;
  font-size: 1.25em;
}

.slots {
  font-weight: 400;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  position: relative;
  box-sizing: border-box;
  background: #c9082c;
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
}

.slot-container {
  display: inline-block;
  width: 32%;
  text-align: center;
}

.slot span {
  font-weight: 600;
  color: #fff;
}

.welcome {
  text-align: left;
  padding: 0;
  margin: 1.25em;
}

.welcome h1 {
  text-transform: capitalize;
}

.welcome p {
  font-size: 1.25em;
}

.welcome p>span {
  font-size: 1.3em;
  font-weight: 600;
}

.welcome span {
  color: #c9082c;
}

.driver-content {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  text-align: left;
  width: 100%;
  min-height: 80px;
  background-color: #fff;
  border-radius: 28px;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  margin-bottom: 24px;
}

.driver-icon {
  margin: 1.25em;
  background-color: #fff;
  max-width: 64px;
  max-height: 64px;
}

.driver-message {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
}

.drivers_name {
  font-size: 1.8em;
  font-weight: 400;
  margin: 0;
  width: 100%;
}

.drivers_name span {
  text-transform: capitalize;
  font-weight: 600;

}

.drivers_reg {
  font-weight: 600;
  font-size: 0.9em;
  width: 100%;
}

.drivers_reg span {
  font-weight: 400;
}

.cta {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  padding: 1.25em;
}

.cta .cta-button {
  background-color: #fff;
  border-radius: 18px;
  border: 1px solid #c9082c;
  color: #c9082c;
  padding: 1em;
  width: 100%;
  font-size: 1.5em;
  font-weight: 600;
  box-sizing: border-box;
}

footer {
  display: flex;
  align-items: center;
  width: 100%;
}

.contact {
  width: 100%;
  font-size: 1.5em;
  font-weight: 400;
  color: #555;
  box-sizing: border-box;
}

.contact a {
  color: #c9082c;
  text-decoration: none;
  font-weight: 600;
}